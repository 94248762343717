import gsap from "gsap";
const burgerIcon = document.querySelector('.mobile-menu-btn');

if(burgerIcon) {
    burgerIcon.addEventListener('click', () => {
        const body = document.querySelector('body');

        if(burgerIcon.classList.contains('active')) {
            // body.classList.remove('fixed');

            burgerIcon.classList.remove('active');
            // Find and toggle active class on SVG paths
            const activePath = burgerIcon.querySelector('path.active');
            const inactivePath = burgerIcon.querySelector('path:not(.active)');
            if (activePath) activePath.classList.remove('active');
            if (inactivePath) inactivePath.classList.add('active');
            gsap.to('.mobile-menu-wrapper', {duration: 0.3, right: '-100%'});
        } else {
            // body.classList.add('fixed');
            
            burgerIcon.classList.add('active');
            // Find and toggle active class on SVG paths
            const activePath = burgerIcon.querySelector('path.active');
            const inactivePath = burgerIcon.querySelector('path:not(.active)');
            if (activePath) activePath.classList.remove('active');
            if (inactivePath) inactivePath.classList.add('active');
            gsap.to('.mobile-menu-wrapper', {duration: 0.3, right: 0});
        }
    });
}

const mobileNavItem = document.querySelectorAll('.mobile-menu-wrapper .mobile-nav-menu__item.has-children');
if(mobileNavItem) {
    mobileNavItem.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.stopPropagation();
            const subMenu = item.querySelector('.mobile-submenu-container');
            const backButton = item.querySelector('.back-button');

            if(subMenu.classList.contains('active')) {
                subMenu.classList.remove('active');
                gsap.to(subMenu, {duration: 0.3, right: '-100%'});
            } else {
                subMenu.classList.add('active');
                gsap.to(subMenu, {duration: 0.3, right: 0});
            }

            if(backButton) {
                backButton.addEventListener('click', (e) => {
                    e.stopPropagation();
                    subMenu.classList.remove('active');
                    gsap.to(subMenu, {duration: 0.3, right: '-100%'});
                });
            }
        });
    });
}

const mobileSubNavItem = document.querySelectorAll('.mobile-submenu-container .sub-nav-container .sub-nav-container__item.has-children');
if(mobileSubNavItem) {
    mobileSubNavItem.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.stopPropagation();
            mobileSetActiveMenu(item, 'sub-nav-active', '.sub-nav-child');
        });
    });
}

const mobileSubNavGrandChild = document.querySelectorAll('.mobile-submenu-container .sub-nav-child .sub-nav-child__item.has-children');
if(mobileSubNavGrandChild) {
    mobileSubNavGrandChild.forEach((item) => {
        item.addEventListener('click', (e) => {
            e.stopPropagation();
            mobileSetActiveMenu(item, 'sub-nav-grandchild-active', '.sub-nav-grandchild');
        });
    });
}

const mobileSetActiveMenu = (item, activeClass, subMenuClass, event) => {

    if(item.classList.contains(activeClass)) {
        item.classList.remove(activeClass);
        gsap.to(item.querySelector(subMenuClass), {duration: 0.3, height: 0});
    } else {
        [...item.parentElement.children].forEach((sib)=> {
            sib.classList.remove(activeClass);
            gsap.to(sib.querySelector(subMenuClass), {duration: 0.3, height: 0});
        });
        item.classList.add(activeClass);
        gsap.to(item.querySelector(subMenuClass), {duration: 0.3, height: 'auto'});
    }
}

document.addEventListener('DOMContentLoaded', function() {
    const accountToggle = document.querySelector('.js-account-toggle');
    const accountDropdown = document.querySelector('.mobile-account-login');

    if (accountToggle && accountDropdown) {
        accountToggle.addEventListener('click', function() {
            if(accountDropdown.classList.contains('active')) {
                accountToggle.classList.remove('active');
                accountDropdown.classList.remove('active');
                gsap.to(accountDropdown, {duration: 0.3, right: '-100%'});
            } else {
                accountToggle.classList.add('active');
                accountDropdown.classList.add('active');
                gsap.to(accountDropdown, {duration: 0.3, right: 0});
            }
        });
    }
});