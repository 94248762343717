import gsap from 'gsap';

function setupFilterButton() {
    const filterThirdLevelButtons = document.querySelectorAll('.filter-third-level-button');
    const filterThirdLevelModal = document.querySelector('.filter-third-level-modal');
    const modalCloseButton = document.querySelector('.modal-close');
    const applyButton = filterThirdLevelModal?.querySelector('.modal-apply');
    const clearButton = filterThirdLevelModal?.querySelector('.modal-clear');

    if (!filterThirdLevelButtons.length || !filterThirdLevelModal) {
        console.warn('Filter elements not found, waiting for DOM...');
        return;
    }

    filterThirdLevelButtons?.forEach(button => {
        button.addEventListener('click', function () {
            filterThirdLevelModal.classList.add('active');
            // Disable page scroll
            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
            document.body.style.width = '100%';
            
            gsap.to(filterThirdLevelModal, {
                backgroundColor: 'rgba(0, 57, 77, 0.15)',
                duration: 0.5,
                ease: 'power1.in'
            });
            
            gsap.to(filterThirdLevelModal.querySelector('.modal-container'), {
                x: 0,
                duration: 0.5,
                ease: 'power1.in'
            });
        });
    });

    function closeModal() {
        gsap.to(filterThirdLevelModal.querySelector('.modal-container'), {
            x: '-200%',
            duration: 0.5,
            ease: 'power1.in'
        });
        
        gsap.to(filterThirdLevelModal, {
            backgroundColor: 'rgba(0, 57, 77, 0)',
            duration: 0.5,
            ease: 'power1.in',
            onComplete: () => {
                filterThirdLevelModal.classList.remove('active');
                // Re-enable page scroll
                document.body.style.overflow = '';
                document.body.style.position = '';
                document.body.style.width = '';
            }
        });
    }

    // Update close button handler
    modalCloseButton.addEventListener('click', closeModal);

    // Update outside click handler
    filterThirdLevelModal.addEventListener('click', function(e) {
        const modalContainer = filterThirdLevelModal.querySelector('.modal-container');
        if (!modalContainer.contains(e.target) && e.target !== modalContainer) {
            closeModal();
        }
    });

    // Update escape key handler
    document.addEventListener('keydown', function(e) {
        if (e.key === 'Escape' && filterThirdLevelModal.classList.contains('active')) {
            closeModal();
        }
    });

    const accordionTriggers = document.querySelectorAll('.accordion-trigger');
    
    accordionTriggers.forEach(trigger => {
        trigger.addEventListener('click', function() {
            const accordionId = this.dataset.accordion;
            const content = document.getElementById(`accordion-${accordionId}`);
            const verticalLine = this.querySelector('.vertical-line');
            
            // Toggle active class for styling
            content.classList.toggle('active');
            verticalLine.classList.toggle('hidden');
            
            // Use GSAP to animate height
            if (content.classList.contains('active')) {
                gsap.to(content, {
                    height: content.scrollHeight,
                    duration: 0.3,
                    ease: "power2.inOut"
                });
            } else {
                gsap.to(content, {
                    height: 0,
                    duration: 0.3,
                    ease: "power2.inOut"
                });
            }
        });
    });

    // Add handler for apply button
    applyButton?.addEventListener('click', () => {
        // Close modal after form submission
        setTimeout(closeModal, 100);
    });

    // Add handler for clear button
    clearButton?.addEventListener('click', () => {
        // Find all checkboxes in the modal and uncheck them
        const checkboxes = filterThirdLevelModal.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
    });
}

// Call setupFilterButton on DOMContentLoaded as well
document.addEventListener('DOMContentLoaded', setupFilterButton);

// Update the observer to be more specific
const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
        const hasFilterElements = mutation.target.querySelector('.filter-third-level-button, .filter-third-level-modal');
        if (hasFilterElements) {
            setupFilterButton();
        }
    });
});

// Start observing with modified parameters
observer.observe(document.body, {
    childList: true,
    subtree: true,
    attributes: true // Add this to catch class changes
});