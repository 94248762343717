import Swiper, {EffectFade, Autoplay, Navigation, Pagination, Thumbs} from "swiper";
import {gsap} from "gsap";
import {isMobile} from './utils';
Swiper.use([EffectFade, Autoplay, Navigation, Pagination, Thumbs]);
import 'swiper/swiper-bundle.min.css';

const productSlider = document.querySelectorAll('.product-image-gallery.swiper');
const relatedProductsSlider = document.querySelectorAll('.related-products-row.swiper');

productSlider.forEach(function (elem, i) {
    const thumbsSlider = new Swiper(".product-image-gallery-thumbs.swiper", {
        modules: [
            Thumbs
        ],
        spaceBetween: 8,
        slidesPerView: 'auto',
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });
    new Swiper(elem, {
        spaceBetween: 10,
        slidesPerView: 1,
        thumbs: {
            swiper: thumbsSlider,
        },
    })
    console.log(thumbsSlider);
})

relatedProductsSlider.forEach(function (elem, i) {
    if (isMobile()) {
        new Swiper(elem, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
                nextEl: '.related-products-container .swiper-button-next',
                prevEl: '.related-products-container .swiper-button-prev',
            },
        })
    }
})