document.addEventListener('DOMContentLoaded', function() {
    const modal = document.getElementById('back-in-stock-modal');
    if (!modal) {
        console.warn('Back in stock modal element not found');
        return;
    }

    const buttons = document.querySelectorAll('.back-in-stock-button');
    if (!buttons.length) {
        console.warn('No back in stock buttons found');
        return;
    }

    const closeButtons = modal.querySelectorAll('.modal-close, .modal-overlay');
    if (!closeButtons.length) {
        console.warn('No close buttons found in modal');
        return;
    }

    console.log(modal);
    
    buttons.forEach(button => {
        button.addEventListener('click', function() {
            const variantId = this.dataset.variantId;
            // Set the variant ID in the hidden input
            modal.querySelector('input[name="variantId"]').value = variantId;
            modal.classList.remove('hidden');
        });
    });

    closeButtons.forEach(button => {
        button.addEventListener('click', function() {
            modal.classList.add('hidden');
        });
    });
});