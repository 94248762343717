document.addEventListener('DOMContentLoaded', function() {
    const businessPersonalTab = document.getElementById('business-personal');
    const tradeAccountTab = document.getElementById('trade-account');
    const businessPersonalForm = document.querySelector('.business-personal-form');
    const tradeAccountForm = document.querySelector('.trade-account-form');

    // Check if all required elements exist
    if (!businessPersonalTab || !tradeAccountTab || !businessPersonalForm || !tradeAccountForm) {
        return;
    }

    function switchTabs(showBusiness) {
        if (showBusiness) {
            businessPersonalForm.classList.add('active');
            tradeAccountForm.classList.remove('active');
        } else {
            businessPersonalForm.classList.remove('active');
            tradeAccountForm.classList.add('active');
        }
    }

    businessPersonalTab.addEventListener('change', () => switchTabs(true));
    tradeAccountTab.addEventListener('change', () => switchTabs(false));
}); 