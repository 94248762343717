document.addEventListener('DOMContentLoaded', function() {
    const megaMenus = document.querySelectorAll('.mega-menu');
    const menuLabels = document.querySelectorAll('.mega-menu__cat-label');

    if (megaMenus.length) {
        megaMenus.forEach(megaMenu => { 

            const parentItem = megaMenu.closest('.mega-menu-parent');
            if (parentItem) {
                parentItem.addEventListener('mouseover', function () {
                    megaMenus.forEach(menu => menu.classList.remove('active'));
                    megaMenu.classList.add('active');
                });
            }

            megaMenu.addEventListener('mouseleave', function () {
                megaMenu.classList.remove('active');
            });
        });
    }

    if (menuLabels.length) {
        menuLabels.forEach(menuLabel => {
            menuLabel.addEventListener('mouseover', function () {
                menuLabel.querySelector('.mega-menu__cat-expand.plus')?.classList.remove('active');
                menuLabel.querySelector('.mega-menu__cat-expand.minus')?.classList.add('active');
            });

            menuLabel.addEventListener('mouseleave', function () {
                menuLabel.querySelector('.mega-menu__cat-expand.plus')?.classList.add('active');
                menuLabel.querySelector('.mega-menu__cat-expand.minus')?.classList.remove('active');
            });
        });
    }
});

