export class VariantInfoTabs {
    constructor() {
        this.init();
    }

    init() {
        const tabList = document.querySelector('.tab-list');
        if (!tabList) return;

        const tabs = tabList.querySelectorAll('.tab-button');
        
        tabs.forEach(tab => {
            tab.addEventListener('click', () => {
                // Remove active class from all tabs and panels
                tabs.forEach(t => t.classList.remove('active'));
                tabs.forEach(t => t.setAttribute('aria-selected', 'false'));
                
                document.querySelectorAll('.tab-panel').forEach(p => {
                    p.classList.remove('active');
                });

                // Add active class to clicked tab and its panel
                tab.classList.add('active');
                tab.setAttribute('aria-selected', 'true');
                
                const panelId = tab.getAttribute('aria-controls');
                const panel = document.getElementById(panelId);
                if (panel) {
                    panel.classList.add('active');
                }
            });
        });
    }
}

// Initialize tabs
document.addEventListener('DOMContentLoaded', () => {
    new VariantInfoTabs();
}); 