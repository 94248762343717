import { algoliasearch } from 'algoliasearch';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';


let searchClient;
let index;

let hits = [];
let nbHits = 0;
const inputSearch = document.querySelector('input#searchProducts');
// const searchButton = document.querySelector('button#searchBtn');
const openSearch = document.querySelector('.cta-container .search-btn');
const searchContainer = document.querySelector('.search-container');
const closeSearch = document.querySelector('.search-container .close-search');
const bodyOverlay = document.body.querySelector('.body-overlay');

searchClient = algoliasearch(
  import.meta.env.VITE_ALGOLIA_APPLICATION_ID,
  import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY
);

autocomplete({
  container: '#autocomplete',
  placeholder: 'Search for products',
  debug: true,
  openOnFocus: true,
  getSources({ query }) {
    return [
      {
        // Suggestions source
        sourceId: 'suggestions',
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: import.meta.env.VITE_ALGOLIA_INDEX_ENVIRONMENT,
                params: {
                  query,
                  hitsPerPage: 3,
                  facets: ['*'], // Enable all facets for suggestions
                  attributesToHighlight: ['title'],
                },
              },
            ],
          });
        },
        templates: {
          header({ html }) {
            return html`
            <div class="aa-SourceHeader">Suggestions</div>`;
          },
          item({ item, components, html }) {
            return html`<div class="aa-ItemWrapper">
              <div class="aa-ItemContent">
                <div class="aa-suggestion-ItemContentBody">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M15 15L10.9581 10.9581M10.9581 10.9581C12.052 9.86411 12.6666 8.38039 12.6666 6.8333C12.6666 5.28621 12.052 3.80249 10.9581 2.70853C9.86411 1.61458 8.38039 1 6.8333 1C5.28621 1 3.80249 1.61458 2.70853 2.70853C1.61458 3.80249 1 5.28621 1 6.8333C1 8.38039 1.61458 9.86411 2.70853 10.9581C3.80249 12.052 5.28621 12.6666 6.8333 12.6666C8.38039 12.6666 9.86411 12.052 10.9581 10.9581Z" stroke="#283343" style="stroke:#283343;stroke:color(display-p3 0.1569 0.2000 0.2627);stroke-opacity:1;" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <a href="${item.url}">${item.title}</a>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                    <path d="M1.00195 1.42187L5.00036 5.42187L1.00195 9.42188" stroke="#283343" style="stroke:#283343;stroke:color(display-p3 0.1569 0.2000 0.2627);stroke-opacity:1;" stroke-linecap="square" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            </div>`;
          },
          footer({ html, state }) {
            return html`
              <a href="/search-results/?s=${state.query}" class="btn btn-primary">See All Product Results</a>
              `;
          },
        },
      },
      {
        // Products source
        sourceId: 'products',
        getItems() {
          return getAlgoliaResults({
            searchClient,
            queries: [
              {
                indexName: import.meta.env.VITE_ALGOLIA_INDEX_ENVIRONMENT,
                params: {
                  query,
                  hitsPerPage: 6,
                },
              },
            ],
          });
        },
        templates: {
          header({ html }) {
            return html`<div class="aa-SourceHeader">Products</div>`;
          },
          item({ item, components, html }) {
            return html`<div class="aa-product-ItemWrapper">
              <div class="aa-ItemContent">
                <div class="aa-ItemImage aa-ItemIcon--alignTop">
                  <svg class="{{ class ?? '' }}"
                    xmlns="http://www.w3.org/2000/svg"
                    width="200"
                    height="200"
                    viewBox="0 0 200 200"
                >
                  <g clip-path="url(#clip0)">
                    <rect fill="#F5F5F5" width="200" height="200"/>
                    <line x1="0" y1="0" x2="200" y2="200" stroke="rgba(255,255,255,0.3)"/>
                    <line x1="0" y1="200" x2="200" y2="0" stroke="rgba(255,255,255,0.3)"/>
                  </g>
                </svg>
                </div>
                <div class="aa-ItemContentBody">
                  <div class="aa-ItemContentTitle">
                    <a href="${item.url}">${item.title}</a>
                  </div>
                </div>
                <div class="aa-ItemContentLink">
                  <a href="${item.url}">
                    View Product
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
                      <path d="M1.00195 1.57812L5.00036 5.57812L1.00195 9.57812" stroke="#283343" style="stroke:#283343;stroke:color(display-p3 0.1569 0.2000 0.2627);stroke-opacity:1;" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                  </a>
                </div>
              </div>
            </div>`;
          },
        },
      },
    ];
  },
});

// async function search(query) {
//   const results = await searchClient.searchSingleIndex({
//     indexName: import.meta.env.VITE_ALGOLIA_INDEX_ENVIRONMENT,
//     searchParams: {
//       query: query,
//       hitsPerPage: 6,
//     }
//   });
//   hits = results.hits;
//   nbHits = results.nbHits;
//   searchResults.innerHTML = '';
//   if(hits.length > 0) {
//     searchResults.innerHTML += `
//       <div class="container">
//           <div class="search-results__items">
//               <div class="search-results__items__suggestions-container">
//                   <div class="search-results__items__suggestions-container__title">
//                       Suggestions
//                   </div>
//                   <div class="search-results__items__suggestions-container__items">
//                   </div>
//               </div>
//               <div class="search-results__items__results-container">
//                   <div class="search-results__items__results-container__title">
//                       Products
//                   </div>
//                   <div class="search-results__items__results-container__items">
//                   `;
//                   hits.forEach((hit) => {
//                     searchResults.innerHTML += `
//                       <div class="search-results__item">
//                         <a href="${hit.url}">${hit.title}</a>
//                       </div>
//                     `;
//                   });
//                   searchResults.innerHTML += `
//                   </div>
//               </div>
//           </div>
//           <a class="view-all-search" href="/search-results/?s=${query}">View All Results (${nbHits})</a>
//       </div>
//     `;

//     if(searchResults.classList.contains('results-showed')) {
//       searchResults.querySelectorAll('.search-results__item a').forEach((link) => {
//         link.addEventListener('click', () => {
//           closeSearchContainer();
//         });
//       });
//     }

//   } else {
//     searchResults.innerHTML += `<div class="search-results__item">No results found for ${query}</div>`;
//   }
// }

// inputSearch.addEventListener('input', async function (e) {
//   if(e.target.value !== '') {
//     search(e.target.value);
//     searchResults.classList.add('results-showed');
//   } else {
//     searchResults.innerHTML = '';
//     searchResults.classList.remove('results-showed');
//   }
// });

// searchButton.addEventListener('click', function (e) {
//     e.preventDefault();
//     window.location.href = `/search-results/?s=${inputSearch.value}`;
// });


if (openSearch) {
  openSearch.addEventListener('click', () => {
    searchContainer.classList.add('active');
    searchContainer.querySelector('input').focus();
    document.body.classList.add('overlay-active');
  });
}
if (closeSearch) {
  closeSearch.addEventListener('click', () => {
    closeSearchContainer();
  });
}
if (bodyOverlay) {
  bodyOverlay.addEventListener('click', () => {
    closeSearchContainer();
  });
}

function closeSearchContainer() {
  searchContainer.classList.remove('active');
  searchContainer.querySelector('input').value = '';
  searchResults.innerHTML = '';
  searchResults.classList.remove('results-showed');
  document.body.classList.remove('overlay-active');
}