document.addEventListener('DOMContentLoaded', function() {
    const tabButtons = document.querySelectorAll('.tab-button');
    const tabPanels = document.querySelectorAll('.tab-panel');
    const mobileSelect = document.querySelector('.mobile-tab-select');
    const pageTitle = document.querySelector('.page-title h1');
    const breadcrumb = document.querySelector('.breadcrumbs li:last-child');

    function activateTab(tabId) {
        // Update tabs
        tabButtons.forEach(btn => {
            const isActive = btn.id === 'tab-' + tabId;
            btn.classList.toggle('active', isActive);
            btn.setAttribute('aria-selected', isActive);
        });
        
        // Update panels
        tabPanels.forEach(panel => {
            const isActive = panel.id === 'panel-' + tabId;
            panel.classList.toggle('active', isActive);
        });
        
        // Update select value
        if (mobileSelect) {
            mobileSelect.value = tabId;
        }
        
        // Update titles
        const activeButton = document.querySelector('#tab-' + tabId);
        if (activeButton) {
            const newTitle = activeButton.textContent.trim();
            if (pageTitle) pageTitle.textContent = newTitle;
            if (breadcrumb) breadcrumb.textContent = newTitle;
            document.title = newTitle + ' | Your Site Name';
        }

        // Update URL without reload
        history.pushState({}, '', `?tab=${tabId}`);
    }

    // Handle tab clicks
    tabButtons.forEach(button => {
        button.addEventListener('click', function() {
            const tabId = this.id.replace('tab-', '');
            activateTab(tabId);
        });
    });

    // Handle mobile select change
    if (mobileSelect) {
        mobileSelect.addEventListener('change', function() {
            activateTab(this.value);
        });
    }

    // Handle browser back/forward
    window.addEventListener('popstate', function() {
        const params = new URLSearchParams(window.location.search);
        const tabId = params.get('tab') || 'account';
        activateTab(tabId);
    });

    // Check URL on page load
    const params = new URLSearchParams(window.location.search);
    const initialTab = params.get('tab') || 'account';
    activateTab(initialTab);

    // Add new function to load order details
    function loadOrderDetails(orderNumber) {
        const ordersPanel = document.querySelector('#panel-orders');
        
        // Store the original content to allow going back
        if (!ordersPanel.getAttribute('data-original-content')) {
            ordersPanel.setAttribute('data-original-content', ordersPanel.innerHTML);
        }

        // Show loading state
        ordersPanel.innerHTML = '<div class="loading">Loading order details...</div>';

        // Add debug logging
        console.log('Fetching order:', `/shop/customer/_includes/order?number=${orderNumber}&ajax=1`);

        // Updated fetch with explicit AJAX header
        fetch(`/shop/customer/_includes/order?number=${orderNumber}&ajax=1`, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => {
                console.log('Response status:', response.status);
                return response.text();
            })
            .then(html => {
                console.log('Received HTML:', html);
                ordersPanel.innerHTML = html;
                
                // Add back button
                const backButton = document.createElement('button');
                backButton.className = 'btn btn-secondary mb-10';
                backButton.innerHTML = '<span>← Back to Orders</span>';
                backButton.addEventListener('click', () => {
                    ordersPanel.innerHTML = ordersPanel.getAttribute('data-original-content');
                });
                
                ordersPanel.insertBefore(backButton, ordersPanel.firstChild);
            })
            .catch(error => {
                console.error('Error loading order:', error);
                ordersPanel.innerHTML = '<div class="error">Error loading order details</div>';
            });
    }

    // Add click handler for order links
    document.addEventListener('click', function(e) {
        const orderLink = e.target.closest('[data-order-number]');
        if (orderLink) {
            e.preventDefault();
            const orderNumber = orderLink.getAttribute('data-order-number');
            
            // Activate orders tab if not already active
            activateTab('orders');
            
            // Load order details
            loadOrderDetails(orderNumber);
        }
    });
});