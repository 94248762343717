document.addEventListener('DOMContentLoaded', () => {
    const dropdownTriggers = document.querySelectorAll('[data-dropdown-trigger]');
    
    dropdownTriggers.forEach(trigger => {
        trigger.addEventListener('click', () => {
            const content = trigger.nextElementSibling;
            const isExpanded = trigger.getAttribute('aria-expanded') === 'true';
            
            // Toggle the dropdown
            trigger.setAttribute('aria-expanded', !isExpanded);
            content.classList.toggle('active');
        });
        
        // Close dropdown when clicking outside
        document.addEventListener('click', (e) => {
            if (!trigger.contains(e.target)) {
                trigger.setAttribute('aria-expanded', 'false');
                trigger.nextElementSibling.classList.remove('active');
            }
        });
    });
});