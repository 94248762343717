document.addEventListener('DOMContentLoaded', function() {
    const modal = document.getElementById('enquiry-modal');
    const openButton = document.getElementById('product-enquiry-open');
    const closeButton = document.getElementById('modal-close');

    // Check if all required elements exist
    if (!modal || !openButton || !closeButton) {
        console.warn('Modal elements not found');
        return;
    }

    function openModal() {
        modal.classList.remove('hidden');
        document.body.style.overflow = 'hidden';
    }

    function closeModal() {
        modal.classList.add('hidden');
        document.body.style.overflow = '';
    }

    openButton.addEventListener('click', openModal);
    closeButton.addEventListener('click', closeModal);

    // Optional: Close modal on escape key
    document.addEventListener('keydown', function(e) {
        if (e.key === 'Escape' && !modal.classList.contains('hidden')) {
            closeModal();
        }
    });
});